@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
body {
    margin: 0;
}

* {
    box-sizing: border-box;
}

.grid-block {
    position: relative;
    width: 100%;
    height: 100%;
}

.grid-block::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.grid-block::-webkit-scrollbar-track {
    background: #dde2ec;
}

.grid-block::-webkit-scrollbar-thumb {
    background: #473e80;
}

.thead {
    position: -webkit-sticky;
    position: sticky;
    z-index: 50;
    top: 0;

    min-width: 100%;
    margin-left: -1px;
    padding-left: 178px;

    -webkit-transform: translateZ(1px);

            transform: translateZ(1px);

    color: #ffffff;
    border-bottom: 2px solid #483d8b;
    background-color: slateblue;
}

.thead-row {
    display: -webkit-flex;
    display: flex;

    min-width: 100%;
}

.thead-row > * {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;

    background-color: slateblue;
}

.thead-row:nth-child(2) {
    margin-bottom: -2px;
}

.thead-cell {
    font-size: 13px;
    line-height: 30px;

    width: 50px;
    height: 30px;

    border: 1px solid #483d8b;
    border-bottom: 0;
}

.thead-cell:first-child {
    border-left-width: 2px;
}

.thead-cell:last-child {
    border-right-width: 2px;
}

.thead-cell.date {
    position: relative;

    display: -webkit-inline-flex;

    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    height: 130px;
}

.thead-cell.weekday {
    display: inline-block;
    margin-top: -1px;
}

.date-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 50px;
    border: 1px solid #483d8b;
    background-color: slateblue;
    color: white;
    font-size: 13px;
    line-height: 30px;
    cursor: default;
}

.date-cell.yellow {
    color: #483d8b;
    background-color: #f8d568;
}

.date-cell.orange {
    color: #483d8b;
    background-color: #ffa342;
}

.date-cell.red {
    color: #483d8b;
    background-color: #ff7a7a;
}

.date-cell-weekday {
    height: 30px;

    text-align: center;
    border-bottom: 2px solid #483d8b;
}

.date-cell-day {
    height: 130px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.date-inner {
    z-index: 55;

    display: inline-block;

    -webkit-transform: rotate(-90deg);

            transform: rotate(-90deg);
    white-space: nowrap;
}

.tbody-row {
    display: -webkit-flex;
    display: flex;
}

.tbody-row:first-child {
    margin-top: -1px;
}

.tbody-body {
    display: -webkit-flex;
    display: flex;

    margin-left: -2px;
}

.tbody-row > * {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.tbody-row:last-child .tbody-cell {
    border-bottom-color: #b1b1b1;
}

.tbody-cell {
    font-size: 0;

    position: relative;

    display: -webkit-inline-flex;

    display: inline-flex;

    width: 50px;
    height: 50px;

    border: 1px solid #b1b1b1;
    cursor: default;
}

.tbody-cell:first-child {
    border-left-width: 2px;
}

.tbody-cell:last-child {
    border-right-width: 2px;
}

.tbody-cell.yellow {
    border-color: rgba(248, 213, 104, .5);;
    background-color: rgba(248, 213, 104, .25);
}

.tbody-cell.orange {
    border-color: rgba(255, 163, 66, .5);
    background-color: rgba(255, 163, 66, .25);
}


.tbody-cell.today {
    border-color: #7c6de0;
    background-color: #968bdc;
}

.tbody-cell.payed {
    border-color: #4bb671;
    background-color: #59e58b;
}

.tbody-cell.pre-ready {
    background: repeating-linear-gradient(-45deg, #d84f9f, #d84f9f 4px, #59e58b 4px, #59e58b 9.5px);
    border: none;
}

.tbody-cell.not-payed {
    border-color: #ca5454;
    background-color: #ff7a7a;
}

.tbody-cell.archived {
    border-color: #b1b1b1;
    background-color: #dde2ec;
}

.tbody-cell.has-refund {
    border-color: #d84f9f;
    background-color: #ff8ace;
}

.tbody-cell.canceled {
    border-color: #ffa342;
    background-color: #f8d568;
}

.tbody-cell.selected {
    border-color: #4049b6;
    border-right-color: transparent;
    border-left-color: transparent;
    background-color: #515cda;
}

.tbody-cell.combined.start {
    border-left-color: #b1b1b1;
}

.tbody-cell.combined.end {
    border-right-color: #b1b1b1;
}

.tbody-cell.payed.start,
.tbody-cell.payed.m-from {
    border-left-color: #4bb671;
}

.tbody-cell.payed.end,
.tbody-cell.payed.m-to {
    border-right-color: #4bb671;
}

.tbody-cell.not-payed.start,
.tbody-cell.not-payed.m-from {
    border-left-color: #ca5454;
}

.tbody-cell.not-payed.end,
.tbody-cell.not-payed.m-to {
    border-right-color: #ca5454;
}

.tbody-cell.archived.start,
.tbody-cell.archived.m-from {
    border-left-color: #b1b1b1;
}

.tbody-cell.archived.end,
.tbody-cell.archived.m-to {
    border-right-color: #b1b1b1;
}

.tbody-cell.has-refund.start,
.tbody-cell.has-refund.m-from {
    border-left-color: #d84f9f;
}

.tbody-cell.has-refund.end,
.tbody-cell.has-refund.m-to {
    border-right-color: #d84f9f;
}

.tbody-cell.start:before,
.tbody-cell.end:after {
    position: absolute;
    z-index: 30;
    top: 0;

    width: 24px;
    height: 48px;

    content: "";
}

.tbody-cell.start:before {
    left: 0;

    background-image: linear-gradient(
            to right,
            #ffffff,
            rgba(255, 255, 255, 0.75),
            transparent
    );
}

.tbody-cell.end:after {
    right: 0;

    background-image: linear-gradient(
            to left,
            #ffffff,
            rgba(255, 255, 255, 0.75),
            transparent
    );
}

.tbody-cell:last-child {
    border-right-color: rgba(0, 0, 0, 0.25);
}

.tbody-cell.combined {
    border-right-color: transparent;
    border-left-color: transparent;
}

.tbody-cell-icon {
    position: relative;
    z-index: 29;

    max-width: 24px;
    max-height: 24px;
    margin: auto;
}

.tbody-cell .client-note,
.tbody-cell .late-check-out {
    position: absolute;
    z-index: 31;
    top: 0;

    width: 24px;
    height: 24px;
    padding: 2px;
}

.tbody-cell .note-exists,
.tbody-cell .reservation-is-group {
    position: absolute;
    z-index: 31;
    bottom: 0;

    width: 24px;
    height: 24px;
    padding: 2px;
}

.tbody-cell .client-note > img,
.tbody-cell .late-check-out > img,
.tbody-cell .note-exists > img,
.tbody-cell .reservation-is-group > img {
    width: 100%;
    height: auto;
}

.tbody-cell .client-note,
.tbody-cell .reservation-is-group {
    left: 0;
}

.tbody-cell .late-check-out,
.tbody-cell .note-exists {
    right: 0;
}

.tbody-cell.repair {
    background-color: #2A2D2F;
}

.tbody-cell.is-busy-by-employee {
    background-color: #7dbdc4;
}

.tbody-cell.overbooking {
  background-color: #b86bb0;
}

.tbody-head {
    display: inline-block;
    z-index: 32;
    left: 0;

    display: -webkit-flex;

    display: flex;
}

.room-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 50px;
    border: 1px solid #483d8b;
    background-color: slateblue;
    color: white;
    font-size: 13px;
    line-height: 50px;
    cursor: pointer;
}

.room-cell-id {
    width: 40px;

    text-align: center;
    border-right: 2px solid #483d8b;
}

.room-cell-name {
    width: 160px;
    padding: 0 16px 0 18px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tbody-headcell {
    font-size: 13px;
    line-height: 50px;

    z-index: 33;

    height: 50px;

    color: #ffffff;
    border: 1px solid #483d8b;
    background-color: slateblue;
}

.tbody-headcell.room-name {
    width: 160px;
    padding: 0 10px;

    text-align: left;

    border-right-width: 2px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tbody-headcell.room-id {
    width: 40px;
    margin-left: -1px;
    text-align: center;
}

.migrated {
    position: absolute;
    z-index: 28;

    border: 8px solid transparent;
}

.migrated.lt {
    top: -2px;
    left: -2px;

    border-top: 16px solid #6874ff;
    border-left: 16px solid #6874ff;
}

.migrated.lb {
    bottom: -2px;
    left: -2px;

    border-bottom: 16px solid #6874ff;
    border-left: 16px solid #6874ff;
}

.migrated.rb {
    right: -2px;
    bottom: -2px;

    border-right: 16px solid #6874ff;
    border-bottom: 16px solid #6874ff;
}

.migrated.rt {
    top: -2px;
    right: -2px;

    border-top: 16px solid #6874ff;
    border-right: 16px solid #6874ff;
}

#grid-tooltip {
    position: fixed;
    z-index: 70;

    width: 240px;
    padding: 15px;

    text-align: left;
    pointer-events: none;

    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}

#grid-tooltip.show {
    opacity: 1;
}

#grid-tooltip:after {
    visibility: hidden;
}

#room-name-tooltip {
    position: fixed;
    z-index: 70;

    width: 240px;
    padding: 12px;

    text-align: left;
    pointer-events: none;

    border-radius: 2px;
    background-color: slateblue;
    box-shadow: 0 2px 5px 0 rgb(48, 38, 109);
}

#room-name-tooltip.show {
    opacity: 1;
}

#room-name-tooltip:after {
    visibility: hidden;
}

.grid-tooltip-field {
    font-size: 14px;
    font-weight: bold;

    display: block;

    color: #444444;
}

.grid-tooltip-value {
    font-size: 15px;
    font-weight: normal;

    display: block;

    margin-top: 5px;
    margin-bottom: 5px;

    content: "";
    white-space: pre-wrap;

    color: #333333;
}

.grid-payment-icon {
    font-size: 24px;
    line-height: 20px;

    display: inline-block;

    margin: auto;
}

.grid-additional-icon {
    font-size: 16px;
    line-height: 18px;

    display: inline-block;
}

.grid-size-80 .room-cell {
    font-size: 12px;
    line-height: 44px;
    height: 44px;
}

.grid-size-80 .date-cell {
    font-size: 12px;
    width: 44px;
}

.grid-size-80 .tbody-cell {
    width: 44px;
    height: 44px;
}

.grid-size-80 .grid-payment-icon {
    font-size: 20px;
    line-height: 18px;
}

.grid-size-80 .grid-additional-icon {
    font-size: 14px;
    line-height: 16px;
}

.grid-size-80 .tbody-cell.start:before,
.grid-size-80 .tbody-cell.end:after {
    width: 21px;
    height: 42px;
}

.grid-size-60 .room-cell {
    font-size: 11px;
    line-height: 38px;
    height: 38px;
}

.grid-size-60 .date-cell {
    font-size: 11px;
    width: 38px;
}

.grid-size-60 .tbody-cell {
    width: 38px;
    height: 38px;
}

.grid-size-60 .grid-payment-icon {
    font-size: 18px;
    line-height: 16px;
}

.grid-size-60 .grid-additional-icon {
    font-size: 13px;
    line-height: 15px;
}

.grid-size-60 .tbody-cell.start:before,
.grid-size-60 .tbody-cell.end:after {
    width: 18px;
    height: 36px;
}

.grid-size-40 .room-cell {
    font-size: 11px;
    line-height: 27px;
    height: 27px;
}

.grid-size-40 .date-cell {
    font-size: 11px;
    width: 27px;
}

.grid-size-40 .tbody-cell {
    width: 27px;
    height: 27px;
}

.grid-size-40 .grid-payment-icon {
    font-size: 15px;
    line-height: 18px;
}

.grid-size-40 .client-note,
.grid-size-40 .late-check-out,
.grid-size-40 .note-exists,
.grid-size-40 .reservation-is-group {
    padding: 0;
    width: auto;
    height: auto;
}

.grid-size-40 .grid-additional-icon {
    font-size: 9px;
    line-height: 12px;
}

.grid-size-40 .tbody-cell.start:before,
.grid-size-40 .tbody-cell.end:after {
    width: 12.5px;
    height: 25px;
}


.iIpChf {
    grid-template-rows: auto;
}

#cell-date-tooltip {
    opacity: 1;
}

#cell-date-tooltip > .tooltip-inner {
    background-color: #fff;
    border-color: #fff;
    font-size: 14px;
    color: #2A2D2F;
    width: 300px;
    padding: 15px;
    text-align: left;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}


#cell-date-tooltip > .tooltip-arrow {
    border-right-color: #fff;
}

html {
  font-size: 13px;
}

body {
  font-family: Roboto, sans-serif !important;

  margin: 0;
  padding: 0;
}

body.no-scroll {
  overflow-y: hidden;
}

.btn {
  font-family: Roboto, sans-serif !important;

  letter-spacing: 0.3px;
}

.right-side h1,
.right-side h2,
.right-side h3,
.right-side h4,
.right-side h5,
.right-side h6 {
  font-family: Roboto, sans-serif !important;
}

html,
body,
#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 80px;

  -webkit-animation: App-logo-spin infinite 20s linear;

          animation: App-logo-spin infinite 20s linear;
}

.App-header {
  height: 150px;
  padding: 20px;

  color: white;
  background-color: #222222;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Content {
  transition: all 500ms ease-in-out;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);

  opacity: 0;
}

.ReactModal__Content--after-open {
  -webkit-transform: translateY(0);
          transform: translateY(0);

  opacity: 1;
}

.ReactModal__Content--before-close {
  -webkit-transform: translateY(30px);
          transform: translateY(30px);

  opacity: 0;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__input-container > input {
  font-family: 'Roboto', sans-serif !important;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  height: 34px;
  line-height: 1.42857143;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.react-datepicker__input-container > input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  display: none;
}

#sell-action-mode {
  padding-left: 20px;
  padding-right: 20px;
}

#sell-action-mode > .tab-content {
  border: 1px solid #ccc;
  border-top: none;
}

.form-group:last-child {
  margin-bottom: 0 !important;
}

.rooms-table {
  margin: 0 16px 0 0;
}

.rooms-table:last-child {
  margin-right: 0;
}

.rooms-table th,
.rooms-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid gainsboro;
}

.inventory-table {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
}

.inventory-table th,
.inventory-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid gainsboro;
}

.breakfast-actions-table {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.breakfast-actions-table th,
.breakfast-actions-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid gainsboro;
}

.loading-spinner {
  position: relative;

  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.cube1,
.cube2 {
  position: absolute;
  top: 0;
  left: 0;

  width: 15px;
  height: 15px;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;

  background-color: #ffffff;
}

.cube2 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
    scale(.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(.5);
    transform: translateX(42px) rotate(-90deg) scale(.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
    scale(.5);
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
